import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";
const { API_BASE_URL } = Config;

export const AddAdvertiser = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user`, formData);
    if (response.data.status === true) {
      sessionStorage.setItem("Token", JSON.stringify(response.data.token));
      sessionStorage.setItem("Id", JSON.stringify(response.data.InsertedId));
      sessionStorage.setItem("Type", JSON.stringify(response.data.Type));
      toast.success(
        "Registration Successful! Get ready to elevate your brand with top-tier influencers and creators."
      );
      return response.data;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// -------------------------------Get User By Id -----------------------------------
// https://www.model.easyshadi.com/front/api/user/1
export const getAdvertiserById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData[0];
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// -------------------------------Get User By Id -----------------------------------
// https://www.model.easyshadi.com/front/api/user/2
export const UpdateAdvertiserById = async (Id, formdata) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user/${Id}`, formdata);
    if (response.data.status === true) {
      toast.success(response.data.message);
      return response.data.responseData;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// ---------------AdvertiserLogin--------------
// https://www.model.easyshadi.com/front/api/advitisor/login
export const AdvertiserLogin = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/advitisor/login`, data);
    if (response.data.status === true) {
      toast.success("User Login Successfully");
      sessionStorage.setItem("Token", JSON.stringify(response.data.token));
      sessionStorage.setItem("Id", JSON.stringify(response.data.UserId));
      sessionStorage.setItem("Type", JSON.stringify(response.data.Type));
      return response.data;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
