import axios from "axios";
import Config from "./Config";
const { API_BASE_URL } = Config;

// https://www.model.easyshadi.com/front/api/services

export const getAllService = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/services`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// -------------------------get Service By Id---------------------
// https://www.model.easyshadi.com/front/api/services/2
export const getServiceById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/services/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData[0];
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
