import axios from "axios";
import Config from "./Config";
const { API_BASE_URL } = Config;

export const getAllCity = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/city`);
    if (response.data.status === true) {
      return response.data.responsedata;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
