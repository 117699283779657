import React, { createContext, useContext, useState, useEffect } from "react";
import { getAllCategory } from "../API/CategoryAPi";
import { getAllCity } from "../API/CityApi";
import { getAllService } from "../API/ServiceApi";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [CategoryData, setCategoryData] = useState([]);
  const [CityData, setCityData] = useState(null);
  const [ServiceData, setServiceData] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryResult, cityResult, serviceResult] = await Promise.all([
          getAllCategory(),
          getAllCity(),
          getAllService(),
        ]);
        setCategoryData(categoryResult);
        setCityData(cityResult);
        setServiceData(serviceResult);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false when data fetching is complete
      }
    };
    fetchData();
  }, []);

  return (
    <DataContext.Provider
      value={{ CategoryData, CityData, ServiceData, loading }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
