/* eslint-disable jsx-a11y/anchor-is-valid */
// /* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import logo from "../../Images/logo.png";
import { IoHomeOutline } from "react-icons/io5";
import { FaInstagram, FaSign, FaSignInAlt, FaUser } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import Config from "../../API/Config";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const toggleSubMenu = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  const closeMenus = () => {
    setIsMenuOpen(false);
    setActiveSubMenu(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const type = Config.getType();
  const Id = Config.getId();

  return (
    <div className="top-0 z-50 right-0 left-0 sticky border-b shadow-md">
      <header className="relative">
        <nav className="bg-white dark:bg-slate-950 border-gray-200 dark:bg-gray-80">
          <div className="flex justify-between items-center w-full md:p-4 p-3  text-black">
            <Link to={"/sign-up"}>
              <a className="flex items-center">
                <img
                  src={logo}
                  className="mr-3 h-12 sm:h-11 bg-transparent bg-white rounded"
                  alt="Logo"
                />
              </a>
            </Link>
            <button
              data-collapse-toggle="mobile-menu-2"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-white ml-auto"
              aria-controls="mobile-menu-2"
              aria-expanded={isSidebarOpen}
              onClick={toggleSidebar}
            >
              <svg
                className={`${isSidebarOpen ? "hidden" : "block"} w-6 h-6`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
              <svg
                className={`${isSidebarOpen ? "block" : "hidden"} w-6 h-6`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>

            <div
              className={`${
                isSidebarOpen ? "block" : "hidden"
              } fixed top-0 right-0 w-64 bg-gray-800 text-white h-full z-50 md:hidden`}
            >
              <div className="p-4">
                <button
                  type="button"
                  className="text-white"
                  onClick={toggleSidebar}
                >
                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              {/* mobile side Bar menu */}
              <ul className="space-y-2 p-4">
                <li className="border-b border-themecolor">
                  <Link
                    to={"/sign-up/advertiser"}
                    onClick={closeSidebar}
                    className="flex items-center p-2 text-white hover:bg-gray-700 rounded"
                  >
                    Sign up as a Advertiser
                  </Link>
                </li>

                <li className="">
                  <Link
                    to={"/sign-up"}
                    onClick={closeSidebar}
                    className="flex items-center p-2 text-white hover:bg-gray-700 rounded"
                  >
                    Join Now - Influencer | Model | Artist
                  </Link>
                </li>
              </ul>
            </div>

            <div className="hidden md:block">
              <ul className=" flex items-center mt-4 font-medium flex-row lg:space-x-3 lg:mt-0">
                <li className="md:my-0 text-lg">
                  <Link
                    to={"/sign-up/advertiser"}
                    className={`${
                      isActive("/sign-up/advertiser")
                        ? "text-white"
                        : "text-white"
                    }
                    flex  bg-themecolor text-center rounded-full px-5 items-center text-sm font-normal py-2 border-gray-100 dark:text-white lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                  >
                    <FaSignInAlt className="mr-2" /> Sign up as a Advertiser
                  </Link>
                </li>

                <li className="p-1 md:my-0 text-lg my-2 bg-[#173559] hover:bg-themecolor text-center rounded-full py-2 px-3">
                  <Link
                    to={"/sign-up"}
                    className={`block text-sm font-normal px-2 rounded ${
                      isActive("/sign-up") ? "text-white" : "text-white"
                    } dark:text-gray-300  lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                  >
                    <span className="uppercase text-xs font-semibold">
                      Join Now -
                    </span>{" "}
                    Influencer | Model | Artist
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
