import React, { useState } from "react";
import ScrollToTop from "../ScrollToTop";
import influencer from "../../../Images/influencer.png";
import model from "../../../Images/model.png";
import adv from "../../../Images/ArtistRegister.png";
import { Link } from "react-router-dom";
import usePageSEO from "../../usepageSEO/Index";

const Generalsignup = () => {
  const [activeTab, setActiveTab] = useState("signUp"); // Default tab is "signUp"

  usePageSEO(
    "Free Influencer, Model and Artist Sign Up - Viralkar", // Use page title for SEO if available
    "Looking to grow your career? Join our free influencer, model, and artist sign-up platform to connect with top brands and showcase your talent.", // Use page description for SEO if available
    [
      "influencer marketing agency india, influencer agency in india, best influencer marketing agency in india, influencer marketplace india, Free influencer sign up, Free sign up as influencer, Free sign up as influencer as model, Free sign up as artist, Free sign up as influencer",
    ]
  );

  return (
    <div>
      <div className="dark:bg-darkmode dark:text-white">
        <ScrollToTop />
        <div className="lg:py-5 py-10 px-5">
          {/* Tabs for Sign Up / Sign In */}

          {/* Conditional Rendering based on Active Tab */}
          {activeTab === "signUp" && (
            <div className="grid container mx-auto grid-cols-1 lg:grid-cols-3 my-10">
              <div className="lg:col-span-3 grid grid-cols-1 lg:grid-cols-3 gap-7">
                <Link
                  to={"/influencer-register"}
                  className="bg-white login--back1 bg-cover-login dark:bg-slate-800 p-3 lg:p-7 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border flex items-center flex-col justify-between"
                >
                  <div className="relative">
                    <img
                      src={influencer}
                      className="w-24 dark:border-white bg-white rounded-full border shadow-md p-2 border-gray-200 mx-auto"
                      alt=""
                    />
                    <p className="text-center text-white font-medium text-2xl my-4 mt-6 dark:text-white">
                      Sign up as an influencer
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Showcase Your Talent :
                      </span>{" "}
                      Share your unique content with a wide audience and get
                      noticed by top brands
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Collaborations :
                      </span>{" "}
                      Connect with other influencers and brands for exciting
                      collaboration opportunities.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Monetize Your Influence :
                      </span>
                      Access paid opportunities and sponsorship deals from
                      global brands.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Exclusive Perks :
                      </span>{" "}
                      Gain access to exclusive offers, events, and early bird
                      opportunities.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Expand Your Network :
                      </span>{" "}
                      Grow your professional network and build lasting
                      relationships with other creators.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Analytics and Insights :
                      </span>{" "}
                      Use platform tools to track your performance and improve
                      content strategies.
                    </p>
                  </div>
                  <div className="flex justify-center md:my-6 mb-0 relative">
                    <Link
                      to={"/influencer-register"}
                      type="submit"
                      className="bg-themecolor hover:bg-gray-500 text-base uppercase text-white p-3 px-10 font-semibold mx-auto rounded-full"
                    >
                      Sign Up
                    </Link>
                  </div>
                </Link>
                <Link
                  to={"/model-register"}
                  className="bg-white login--back2 bg-cover-login dark:bg-slate-800 p-3 lg:p-7 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border flex items-center flex-col justify-between"
                >
                  <div className="relative">
                    <img
                      src={model}
                      className="w-24 dark:border-white bg-white rounded-full border shadow-md p-2 border-gray-200 mx-auto"
                      alt=""
                    />
                    <p className="text-center text-white font-medium text-2xl my-4 mt-6 dark:text-white">
                      Sign up as a Model
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Create a Stunning Portfolio :
                      </span>{" "}
                      Showcase your best photos and videos to top fashion
                      brands, photographers, and agencies.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Global Exposure :
                      </span>{" "}
                      Be discovered by top modeling agencies and casting
                      directors worldwide.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Work Opportunities :{" "}
                      </span>{" "}
                      Get access to modeling gigs, runway shows, and
                      photoshoots.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Collaboration Options :
                      </span>{" "}
                      Work with designers, photographers, and stylists to build
                      your portfolio.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Become a Star :{" "}
                      </span>{" "}
                      Step into the spotlight and build your career as a
                      professional model.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Secure Parental Consent (For Kids) :
                      </span>{" "}
                      Parents can easily manage registrations for young models
                      with proper consent.
                    </p>
                  </div>
                  <div className="flex justify-center md:my-6 mb-0 relative">
                    <button
                      type="submit"
                      className="bg-themecolor hover:bg-gray-500 text-base uppercase text-white p-3 px-10 font-semibold mx-auto rounded-full"
                    >
                      Sign Up
                    </button>
                  </div>
                </Link>
                <Link
                  to={"/artist-register"}
                  className="bg-white login--back3 bg-cover-login dark:bg-slate-800 p-3 lg:p-7 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border flex items-center flex-col justify-between"
                >
                  <div className="relative">
                    <img
                      src={adv}
                      className="w-24 dark:border-white bg-white rounded-full border shadow-md p-2 border-gray-200 mx-auto"
                      alt=""
                    />
                    <p className="text-center text-white font-medium text-2xl my-4 mt-6 dark:text-white">
                      Sign up as an Artist
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Showcase Your Art :
                      </span>{" "}
                      Display your creative work to a large audience of
                      potential buyers, collectors, and brands.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        {" "}
                        Sell Your Art :
                      </span>{" "}
                      Use the platform to sell your artwork directly to
                      customers.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Networking Opportunities :
                      </span>{" "}
                      Connect with other artists, galleries, and creative
                      professionals.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Collaborations :
                      </span>{" "}
                      Work with brands and fellow artists on commissioned
                      projects.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Build Your Reputation :
                      </span>{" "}
                      Gain exposure, followers, and build a fan base for your
                      artistic creations.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Online Portfolio :
                      </span>{" "}
                      Create a professional online portfolio to present your
                      best work.
                    </p>
                  </div>
                  <div className="flex justify-center md:my-6 mb-0 relative">
                    <button
                      type="submit"
                      className="bg-themecolor hover:bg-gray-500 text-base uppercase text-white p-3 px-10 font-semibold mx-auto rounded-full"
                    >
                      Sign Up
                    </button>
                  </div>
                </Link>
              </div>
            </div>
          )}

          {activeTab === "signIn" && (
            <div className="grid container mx-auto grid-cols-1 lg:grid-cols-3 my-10">
              <div className="col-span-3 grid grid-cols-1 lg:grid-cols-3 gap-7">
                <Link
                  to={"/influencer-register"}
                  className="bg-white login--back1 bg-cover-login dark:bg-slate-800 p-3 lg:p-7 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border flex items-center flex-col justify-between"
                >
                  <div className="relative">
                    <img
                      src={influencer}
                      className="w-24 dark:border-white bg-white rounded-full border shadow-md p-2 border-gray-200 mx-auto"
                      alt=""
                    />
                    <p className="text-center text-white font-medium text-2xl my-4 mt-6 dark:text-white">
                      Login as an influencer
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Showcase Your Talent :
                      </span>{" "}
                      Share your unique content with a wide audience and get
                      noticed by top brands
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Collaborations :
                      </span>{" "}
                      Connect with other influencers and brands for exciting
                      collaboration opportunities.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Monetize Your Influence :
                      </span>
                      Access paid opportunities and sponsorship deals from
                      global brands.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Exclusive Perks :
                      </span>{" "}
                      Gain access to exclusive offers, events, and early bird
                      opportunities.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Expand Your Network :
                      </span>{" "}
                      Grow your professional network and build lasting
                      relationships with other creators.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Analytics and Insights :
                      </span>{" "}
                      Use platform tools to track your performance and improve
                      content strategies.
                    </p>
                  </div>
                  <div className="flex justify-center md:my-6 mb-0 relative">
                    <Link
                      to={"/influencer-register"}
                      type="submit"
                      className="bg-themecolor hover:bg-gray-500 text-base uppercase text-white p-3 px-10 font-semibold mx-auto rounded-full"
                    >
                      Login
                    </Link>
                  </div>
                </Link>
                <Link
                  to={"/model-register"}
                  className="bg-white login--back2 bg-cover-login dark:bg-slate-800 p-3 lg:p-7 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border flex items-center flex-col justify-between"
                >
                  <div className="relative">
                    <img
                      src={model}
                      className="w-24 dark:border-white bg-white rounded-full border shadow-md p-2 border-gray-200 mx-auto"
                      alt=""
                    />
                    <p className="text-center text-white font-medium text-2xl my-4 mt-6 dark:text-white">
                      Login as a Model
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Create a Stunning Portfolio :
                      </span>{" "}
                      Showcase your best photos and videos to top fashion
                      brands, photographers, and agencies.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Global Exposure :
                      </span>{" "}
                      Be discovered by top modeling agencies and casting
                      directors worldwide.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Work Opportunities :{" "}
                      </span>{" "}
                      Get access to modeling gigs, runway shows, and
                      photoshoots.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Collaboration Options :
                      </span>{" "}
                      Work with designers, photographers, and stylists to build
                      your portfolio.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Become a Star :{" "}
                      </span>{" "}
                      Step into the spotlight and build your career as a
                      professional model.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Secure Parental Consent (For Kids) :
                      </span>{" "}
                      Parents can easily manage registrations for young models
                      with proper consent.
                    </p>
                  </div>
                  <div className="flex justify-center md:my-6 mb-0 relative">
                    <button
                      type="submit"
                      className="bg-themecolor hover:bg-gray-500 text-base uppercase text-white p-3 px-10 font-semibold mx-auto rounded-full"
                    >
                      Login
                    </button>
                  </div>
                </Link>
                <Link
                  to={"/artist-register"}
                  className="bg-white login--back3 bg-cover-login dark:bg-slate-800 p-3 lg:p-7 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border flex items-center flex-col justify-between"
                >
                  <div className="relative">
                    <img
                      src={adv}
                      className="w-24 dark:border-white bg-white rounded-full border shadow-md p-2 border-gray-200 mx-auto"
                      alt=""
                    />
                    <p className="text-center text-white font-medium text-2xl my-4 mt-6 dark:text-white">
                      Login as an Artist
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Showcase Your Art :
                      </span>{" "}
                      Display your creative work to a large audience of
                      potential buyers, collectors, and brands.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        {" "}
                        Sell Your Art :
                      </span>{" "}
                      Use the platform to sell your artwork directly to
                      customers.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Networking Opportunities :
                      </span>{" "}
                      Connect with other artists, galleries, and creative
                      professionals.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Collaborations :
                      </span>{" "}
                      Work with brands and fellow artists on commissioned
                      projects.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Build Your Reputation :
                      </span>{" "}
                      Gain exposure, followers, and build a fan base for your
                      artistic creations.
                    </p>
                    <p className="text-base text-gray-300 font-normal dark:text-white my-3">
                      <span className="text-white font-semibold">
                        Online Portfolio :
                      </span>{" "}
                      Create a professional online portfolio to present your
                      best work.
                    </p>
                  </div>
                  <div className="flex justify-center md:my-6 mb-0 relative">
                    <button
                      type="submit"
                      className="bg-themecolor hover:bg-gray-500 text-base uppercase text-white p-3 px-10 font-semibold mx-auto rounded-full"
                    >
                      Login
                    </button>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Generalsignup;
