import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Attempt to scroll window
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100); // Delay to ensure DOM updates

    // Check and scroll a custom scrollable container if needed
    const scrollableElement = document.getElementById("scrollable-element-id");
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;
