import React from "react";
import CheckImg from "../../../Images/verified.gif";
const RegisterSuccessful = () => {
  return (
    <div className="dark:bg-darkmode dark:text-white">
      <div className="container mx-auto lg:py-32 py-10 px-3">
        <div className="grid lg:grid-cols-4 grid-cols-1">
          <div className=""></div>
          <div className="col-span-2 mx-auto text-center">
            <img
              src={CheckImg}
              alt="Registration Successful"
              className="h-40 mx-auto  rounded-full"
            />
            <h2 className="text-center text-3xl font-semibold mb-4">
              Registration Successful
            </h2>
            <p className="text-xl mb-4">Welcome to ViralKar.com!</p>
            <p className="text-lg mb-4">
              Congratulations! Your registration has been successfully
              completed. We're thrilled to have you join our community of
              fashion enthusiasts and industry professionals.
            </p>
          </div>
          <div className=""></div>
        </div>
      </div>
    </div>
  );
};
export default RegisterSuccessful;
