import React, { useState } from "react";
import titleimage from "../../../../Images/title-icon.png";
import logo from "../../../../Images/logo.png";
import loginbg from "../../../../Images/loginbg.png";
import { useFormik } from "formik";

import * as Yup from "yup";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import { useNavigate } from "react-router-dom";
import sectionbg from "../../../../Images/ArtistRegister-bg.jpg";
import usePageSEO from "../../../usepageSEO/Index";
import {
  ArtistStatusCheck,
  GoogleLoginAtristAPI,
} from "../../../../API/ArtistSignUpAPI";
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

const validationSchema = Yup.object().shape({
  UserName: Yup.string()
    .required("Email address / Mobile Number is required")
    .test(
      "is-valid-username",
      "Invalid User name. Please enter a valid email address, mobile number.",
      function (value) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
        const mobileRegex = /^[0-9]{10}$/; // Assuming 10 digit mobile number

        // Check if value matches any of the regexes
        if (emailRegex.test(value) || mobileRegex.test(value)) {
          return true;
        }
        return false;
      }
    ),
});

const LoginArtist = () => {
  usePageSEO(
    "Free Artist Register - ViralKar", // Use page title for SEO if available
    "Need more exposure for your art? Join our free artist register to connect with clients, showcase your work, and grow your creative career today.", // Use page description for SEO if available
    [
      "Free Artist Register, Artist Register, Register as Artist, Register as Artist for free",
    ] // No keywords provided in this example
  );

  const [isFormLoading, setIsFormLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      UserName: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);

      try {
        const response = await ArtistStatusCheck(values, navigate);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const handleGoogleSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;
    try {
      const user = jwtDecode(token);

      const loginResponse = await GoogleLoginAtristAPI(
        {
          AuthId: user.sub,
          Email: user.email,
          UserName: user.name,
        },
        navigate
      );
      // console.log("Google Login Response:", loginResponse);
      // const redirectUrl = sessionStorage.getItem("redirectUrl");
      // sessionStorage.removeItem("redirectUrl");
      // if (loginResponse.status === true) {
      //   navigate(redirectUrl || "/ESD");
      //   window.location.reload();
      // }
    } catch (error) {
      console.error("Google Login Failed:", error);
    }
  };

  const handleGoogleFailure = (error) => {
    console.error("Google Login Failed", error);
  };

  const responseFacebook = (response) => {
    console.log("Facebook Login Success", response);
    // Handle the Facebook login response (e.g., send to backend)
  };

  const handleInstagramLogin = () => {
    const clientId = "YOUR_INSTAGRAM_CLIENT_ID"; // Replace with your Instagram client ID
    const redirectUri = "YOUR_REDIRECT_URI"; // Replace with your redirect URI
    // const instagramAuthUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`;
    const instagramAuthUrl = `#`;

    // window.location.href = instagramAuthUrl;
  };
  return (
    <div>
      <GoogleOAuthProvider clientId="296529218120-123t9cn85r6lt28tollhrk2c2uhdv3rh.apps.googleusercontent.com">
        <div className="bg-dark">
          {isFormLoading && <FormLoader loading={isFormLoading} />}
          <ScrollToTop />
          <div className="py-14 px-5 md:px-0">
            <div className="flex items-center justify-center flex-col">
              <p className="page-title text-center">
                Unleash your creativity-register or log in to let your art shine
              </p>

              <img src={titleimage} className="text-center title-icon" alt="" />
            </div>

            <form onSubmit={formik.handleSubmit} className=" py-10">
              <div className="flex items-center justify-center">
                <div className="md:w-4/5 xl:w-3/5 rounded-lg bg-dark-light p-8 grid lg:grid-cols-2 grid-cols-1 dark:border border-white">
                  <div className="col-span-1 my-auto md:mx-5 md:pr-10">
                    <img
                      src={logo}
                      className="bg-white p-2 w-64 rounded mx-auto mb-10"
                      alt=""
                    />
                    <div className="mb-4">
                      <label for="">
                        Email Address / Mobile Number{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="UserName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Email address / Mobile Number"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.UserName && formik.errors.UserName ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.UserName}
                        </div>
                      ) : null}
                    </div>

                    <div className="flex justify-center my-3">
                      <button
                        type="submit"
                        className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 mx-auto"
                      >
                        Register
                      </button>
                    </div>

                    <div className="relative py-3">
                      <div className="border border-zinc-200"></div>
                      <p className="text-center absolute px-2 top-0 left-1/2 transform -translate-x-1/2  bg-white dark:bg-black">
                        Or
                      </p>
                    </div>
                    {/* Google Login */}
                    <div className="flex justify-center my-3">
                      <GoogleLogin
                        onSuccess={handleGoogleSuccess}
                        onError={handleGoogleFailure}
                        text="continue_with"
                      />
                    </div>
                    <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-4">
                      {/* Facebook Login */}
                      <div className="flex justify-center items-center bg-blue-600 text-sm rounded-full my-3 w-full">
                        <span className="text-white">
                          <FaFacebookF />
                        </span>
                        <FacebookLogin
                          appId="YOUR_FACEBOOK_APP_ID"
                          autoLoad={false}
                          fields="name,email,picture"
                          callback={responseFacebook}
                          cssClass="text-white p-2 rounded w-full text-center"
                        />
                      </div>

                      {/* Instagram Login */}
                      <div className="flex justify-center items-center my-3 w-full rounded-full text-sm bg-pink-600">
                        <button
                          type="button"
                          className=" text-white p-2 py-3 rounded w-full text-center flex items-center justify-center"
                          onClick={handleInstagramLogin}
                        >
                          <span className="text-white mr-2">
                            <FaInstagram />
                          </span>
                          Login with Instagram
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 hidden md:block mt-10 md:mt-0 relative">
                    <img src={sectionbg} alt="" className="rounded-lg" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </GoogleOAuthProvider>
    </div>
  );
};

export default LoginArtist;
