import axios from "axios";
import Config from "./Config";
const { API_BASE_URL } = Config;
// const headers = {
//     Authorization: `Bearer ${TOKEN}`, // Corrected typo from "Bareer" to "Bearer"
//   };
export const getAllCollbration = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/collabration`);
    if (response.data.status === true) {
      return response.data.responsedata;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
