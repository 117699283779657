import React from "react";
import CheckImg from "../../../Images/verified.gif";
const LoginSuccessful = () => {
  return (
    <div className="dark:bg-darkmode dark:text-white">
      <div className="container mx-auto lg:py-20 py-10 px-3">
        <div className="grid lg:grid-cols-4 grid-cols-1">
          <div className=""></div>
          <div className="col-span-2 mx-auto text-center">
            <img
              src={CheckImg}
              alt="Login Successful"
              className="h-40 mx-auto  rounded-full"
            />
            <h2 className="text-center text-3xl font-semibold mb-4">
              Login Successful
            </h2>
            <p className="text-xl mb-4">Welcome to ViralKar.com!</p>
            <p className="text-lg mb-4">
              Welcome aboard! Your access to the premier marketplace for top
              influencers, artists, and models is now unlocked.
            </p>
            <p className="text-lg mb-4">
              <b>Get Ready to Shine:</b> We’re gearing up for an exclusive
              experience designed just for you. Our platform is your gateway to
              connect with industry leaders, showcase your talent, and explore
              unparalleled opportunities.
            </p>
            <p className="text-lg mb-4">
              <b>Stay Tuned:</b> Exciting features and updates are coming your
              way soon. We’re working hard to make sure your journey with us is
              nothing short of extraordinary.
            </p>
            <p className="text-lg mb-4">
              <b>Need Help?: </b>Our support team is here for you every step of
              the way. If you have any questions or need assistance, don’t
              hesitate to reach out. Thank you for joining us. Let’s make magic
              happen together!
            </p>
          </div>
          <div className=""></div>
        </div>
      </div>
    </div>
  );
};
export default LoginSuccessful;
