import React from "react";
import Layout from "./Layout/Layout";
import CookieConsent from "./Context/CookieConsent";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Layout />
      <CookieConsent />
    </LocalizationProvider>
  );
};

export default App;
