// import React from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Home from "../Component/Pages/Home";
// import Header from "../Component/Header/Header";
// import Footer from "../Component/Footer/Footer";
// import Contact from "../Component/Pages/Contact";
// import MaleInfluencer from "../Component/Pages/Influencer/MaleInfluencer";
// import ForgotPassword from "../Component/Pages/Login-Register/ForgotPassword";
// import Generalsignup from "../Component/Pages/Login-Register/Generalsignup";
// import FemaleInfluencer from "../Component/Pages/Influencer/FemaleInfluencer";
// import RegisterEnterprice from "../Component/Pages/Register/RegisterEnterprice";
// import RequireAuth from "../Private/Requiredau";
// import AdvProfile from "../Component/Pages/Dashboard/AdvProfile";
// import Service from "../Component/Pages/Services/Service";
// import ServiceInner from "../Component/Pages/Services/ServiceInner";
// import ScrollToTop from "../Component/Pages/ScrollToTop";
// import HowItWorks from "../Component/Pages/Influencer/Howitwork";
// import KidsModel from "../Component/Pages/ModelCat/KidsModel";
// import MaleModel from "../Component/Pages/ModelCat/MaleModel";
// import FemaleModel from "../Component/Pages/ModelCat/FemaleModel";
// import PlanModel from "../Component/Pages/Plan/PlanModel";
// import ModelProfile from "../Component/Pages/Dashboard/ModelProfile";
// import Pages from "../Component/Pages/Pages";
// import Category from "../Component/Pages/Category";
// import Hiremodel from "../Component/Pages/Hire/Hiremodel";
// import HireInfluencer from "../Component/Pages/Hire/HireInfluencer";
// import Campaigns from "../Component/Pages/Dashboard/Campaign/Campaigns";
// import AddCampaign from "../Component/Pages/Dashboard/Campaign/AddCampaign";
// import EditCampaign from "../Component/Pages/Dashboard/Campaign/EditCampaign";
// import Enquiry from "../Component/Pages/Dashboard/Enquiry";
// import CityInfluencer from "../Component/Pages/CityInfluencer";
// import ModelCampaign from "../Component/Pages/Dashboard/ModelCampaign";
// import ApplyCampaign from "../Component/Pages/Dashboard/ApplyCampaign";
// import InfluencerApplied from "../Component/Pages/Dashboard/Campaign/InfluencerApplied";
// import AboutUs from "../Component/Pages/AboutUs";
// import Blog from "../Component/Pages/Blog";
// import BlogDetails from "../Component/Pages/BlogDetails";
// import FAQ from "../Component/Pages/FAQ";
// import PrivacyPolicy from "../Component/Pages/PrivacyPolicy";
// import TermsCondition from "../Component/Pages/TermsCondition";
// import LoginPasswords from "../Component/Pages/LoginMain/Influencer/LoginPassword";
// import LoginInfluencer from "../Component/Pages/LoginMain/Influencer/LoginInfluencer";
// import LoginModel from "../Component/Pages/LoginMain/Model/LoginModel";
// import LoginPasswordModel from "../Component/Pages/LoginMain/Model/LoginPasswordModel";
// import RegisterModel from "../Component/Pages/LoginMain/Model/RegisterModel";
// import RegisterModel2 from "../Component/Pages/LoginMain/Model/RegisterModel2";
// import RegisterModel3 from "../Component/Pages/LoginMain/Model/RegisterModel3";
// import RegisterArtist from "../Component/Pages/LoginMain/Artist/RegisterArtist";
// import RegisterArtist2 from "../Component/Pages/LoginMain/Artist/RegisterArtist2";
// import LoginArtist from "../Component/Pages/LoginMain/Artist/LoginArtist";
// import LoginPasswordArtist from "../Component/Pages/LoginMain/Artist/LoginPasswordArtist";
// import RegisterArtist3 from "../Component/Pages/LoginMain/Artist/RegisterArtist3";
// import RegisterSuccessful from "../Component/Pages/LoginMain/RegisterSuccessful";
// import RegisterInfluencerNew2 from "../Component/Pages/LoginMain/Influencer/RegisterInfluencer2";
// import RegisterInfluencerNew from "../Component/Pages/LoginMain/Influencer/RegisterInfluencer";
// import RegisterInfluencerNew3 from "../Component/Pages/LoginMain/Influencer/RegisterInfluencer3";
// import CatSubCategory from "../Component/Pages/CatSubCategory";
// import InfluencerDetail from "../Component/Pages/InfluencerDetail";
// import ModelDetail from "../Component/Pages/ModelDetail";
// import ArtistDetail from "../Component/Pages/ArtistDetail";
// import FindInfluencer from "../Component/Pages/FindInfluencer";
// import Artist from "../Component/Pages/Artist";
// import LoginSuccessful from "../Component/Pages/LoginMain/LoginSuccessful";

// const MainRouter = () => {
//   return (
//     <div>
//       <BrowserRouter>
//         <ScrollToTop />
//         <Header />
//         <Routes>
//           <Route path="/" exact element={<Home />} />
//           {/*YES*/}
//           {/* ====================About Us ======================*/}
//           <Route path="/about-us" element={<AboutUs />} />
//           {/*YES*/}
//           {/* ==================== FAQ ======================*/}
//           <Route path="/faq" element={<FAQ />} />
//           {/*YES*/}
//           <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//           {/*YES*/}
//           {/* ==================== Blog ======================*/}
//           <Route path="/blog" element={<Blog />} /> {/*YES*/}
//           <Route path="/blog-details" element={<BlogDetails />} />
//           {/*YES*/}
//           <Route path="/terms-&-codtion" element={<TermsCondition />} />
//           {/*YES*/}
//           <Route path="/contact" element={<Contact />} />
//           {/*YES*/}
//           <Route path="/forgot-password" element={<ForgotPassword />} />
//           {/*YES*/}
//           <Route path="/sign-up" element={<Generalsignup />} />
//           {/*YES*/}
//           <Route path="/pages/:Slug" element={<Pages />} />
//           {/*YES*/}
//           <Route path="/sign-up/advertiser" element={<RegisterEnterprice />} />
//           {/* -------------------------- Influencer ---------------------------*/}
//           <Route path="/male-influencer" element={<MaleInfluencer />} />
//           <Route path="/female-influencer" element={<FemaleInfluencer />} />
//           <Route path="/how-its-work" element={<HowItWorks />} />
//           {/* ------------------------------- Influncer ------------------*/}
//           <Route path="/influencer/:Id" element={<InfluencerDetail />} />
//           <Route path="/find-influencer" element={<FindInfluencer />} />
//           {/* ------------------------------- Model ------------------*/}
//           <Route path="/model/:Id" element={<ModelDetail />} />
//           {/* ------------------------------- Artist ------------------*/}
//           <Route path="/artist" element={<Artist />} />
//           <Route path="/artist/:Id" element={<ArtistDetail />} />
//           {/* ----------------------------- Category -------------------------------*/}
//           <Route path="/category/:Slug" element={<Category />} />
//           <Route path="/category/:Slug1/:Slug" element={<CatSubCategory />} />
//           <Route path="/city/:Slug" element={<CityInfluencer />} />
//           {/* ----------------------------- Modal Category -------------------------------*/}
//           <Route path="/female-models" element={<FemaleModel />} />
//           <Route path="/male-models" element={<MaleModel />} />
//           <Route path="/kids-models" element={<KidsModel />} />
//           {/*------------------------Service--------------------------*/}
//           <Route path="/service" element={<Service />} />
//           <Route path="/service/:Id" element={<ServiceInner />} />
//           {/*------------------------Hire--------------------------*/}
//           <Route path="/hire-model" element={<Hiremodel />} />
//           <Route path="/hire-influencer" element={<HireInfluencer />} />
//           {/* --------------------------------- Plan -------------------*/}
//           <Route path="/plan" element={<PlanModel />} />
//           {/*------------------------dashboard--------------------------*/}
//           <Route
//             path="/profile/advertiser"
//             element={
//               <RequireAuth>
//                 <AdvProfile />
//               </RequireAuth>
//             }
//           />
//           <Route
//             path="/profile/model"
//             element={
//               <RequireAuth>
//                 <ModelProfile />
//               </RequireAuth>
//             }
//           />
//           <Route
//             path="/campaign"
//             element={
//               <RequireAuth>
//                 <ModelCampaign />
//               </RequireAuth>
//             }
//           />
//           <Route
//             path="/campaign/apply/:Id"
//             element={
//               <RequireAuth>
//                 <ApplyCampaign />
//               </RequireAuth>
//             }
//           />
//           <Route
//             path="/campaigns"
//             element={
//               <RequireAuth>
//                 <Campaigns />
//               </RequireAuth>
//             }
//           />
//           <Route
//             path="/campaigns/add"
//             element={
//               <RequireAuth>
//                 <AddCampaign />
//               </RequireAuth>
//             }
//           />
//           <Route
//             path="/campaigns/edit/:Id"
//             element={
//               <RequireAuth>
//                 <EditCampaign />
//               </RequireAuth>
//             }
//           />
//           <Route
//             path="/enquiry"
//             element={
//               <RequireAuth>
//                 <Enquiry />
//               </RequireAuth>
//             }
//           />
//           <Route
//             path="/influencer/applied/:Id"
//             element={
//               <RequireAuth>
//                 <InfluencerApplied />
//               </RequireAuth>
//             }
//           />
//           {/*-------------------------------Model-influencer-artist----------------------*/}
//           {/*------------------------login/register--------------------------*/}
//           <Route
//             path="/influencer/sign-up/influencer"
//             element={<RegisterInfluencerNew />}
//           />
//           <Route
//             path="/influencer/sign-up/influencer2"
//             element={<RegisterInfluencerNew2 />}
//           />
//           <Route
//             path="/influencer/sign-up/influencer3"
//             element={<RegisterInfluencerNew3 />}
//           />
//           <Route path="/influencer-register" element={<LoginInfluencer />} />
//           <Route path="/influencer-password" element={<LoginPasswords />} />
//           {/*------------------------login/register Model--------------------------*/}
//           <Route path="/model/sign-up/model" element={<RegisterModel />} />
//           <Route path="/model/sign-up/model2" element={<RegisterModel2 />} />
//           <Route path="/model/sign-up/model3" element={<RegisterModel3 />} />
//           <Route path="/model-register" element={<LoginModel />} />
//           <Route path="/model-password" element={<LoginPasswordModel />} />
//           {/*------------------------login/register Artist--------------------------*/}
//           <Route path="/artist/sign-up/artist" element={<RegisterArtist />} />
//           <Route path="/artist/sign-up/artist2" element={<RegisterArtist2 />} />
//           <Route path="/artist/sign-up/artist3" element={<RegisterArtist3 />} />
//           <Route path="/artist-register" element={<LoginArtist />} />
//           <Route path="/artist-password" element={<LoginPasswordArtist />} />
//           <Route path="/register/success" element={<RegisterSuccessful />} />
//           <Route path="/login/success" element={<LoginSuccessful />} />
//         </Routes>

//         <Footer />
//       </BrowserRouter>
//     </div>
//   );
// };

// export default MainRouter;

import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import Generalsignup from "../Component/Pages/Login-Register/Generalsignup";
import RegisterEnterprice from "../Component/Pages/Register/RegisterEnterprice";
import ScrollToTop from "../Component/Pages/ScrollToTop";
import LoginPasswords from "../Component/Pages/LoginMain/Influencer/LoginPassword";
import LoginInfluencer from "../Component/Pages/LoginMain/Influencer/LoginInfluencer";
import LoginModel from "../Component/Pages/LoginMain/Model/LoginModel";
import LoginPasswordModel from "../Component/Pages/LoginMain/Model/LoginPasswordModel";
import RegisterModel from "../Component/Pages/LoginMain/Model/RegisterModel";
import RegisterModel2 from "../Component/Pages/LoginMain/Model/RegisterModel2";
import RegisterModel3 from "../Component/Pages/LoginMain/Model/RegisterModel3";
import RegisterArtist from "../Component/Pages/LoginMain/Artist/RegisterArtist";
import RegisterArtist2 from "../Component/Pages/LoginMain/Artist/RegisterArtist2";
import LoginArtist from "../Component/Pages/LoginMain/Artist/LoginArtist";
import LoginPasswordArtist from "../Component/Pages/LoginMain/Artist/LoginPasswordArtist";
import RegisterArtist3 from "../Component/Pages/LoginMain/Artist/RegisterArtist3";
import RegisterSuccessful from "../Component/Pages/LoginMain/RegisterSuccessful";
import RegisterInfluencerNew2 from "../Component/Pages/LoginMain/Influencer/RegisterInfluencer2";
import RegisterInfluencerNew from "../Component/Pages/LoginMain/Influencer/RegisterInfluencer";
import RegisterInfluencerNew3 from "../Component/Pages/LoginMain/Influencer/RegisterInfluencer3";
import LoginSuccessful from "../Component/Pages/LoginMain/LoginSuccessful";

const MainRouter = () => {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          {/*YES*/}
          {/* ====================About Us ======================*/}
          <Route path="/sign-up" element={<Generalsignup />} />
          {/*YES*/}
          {/* ==================== FAQ ======================*/}
          {/*YES*/}
          {/*YES*/}
          {/* ==================== Blog ======================*/}

          {/*YES*/}

          <Route path="/sign-up/advertiser" element={<RegisterEnterprice />} />

          {/*------------------------dashboard--------------------------*/}

          {/*-------------------------------Model-influencer-artist----------------------*/}
          {/*------------------------login/register--------------------------*/}
          <Route
            path="/influencer/sign-up/influencer"
            element={<RegisterInfluencerNew />}
          />
          <Route
            path="/influencer/sign-up/influencer2"
            element={<RegisterInfluencerNew2 />}
          />
          <Route
            path="/influencer/sign-up/influencer3"
            element={<RegisterInfluencerNew3 />}
          />
          <Route path="/influencer-register" element={<LoginInfluencer />} />
          <Route path="/influencer-password" element={<LoginPasswords />} />
          {/*------------------------login/register Model--------------------------*/}
          <Route path="/model/sign-up/model" element={<RegisterModel />} />
          <Route path="/model/sign-up/model2" element={<RegisterModel2 />} />
          <Route path="/model/sign-up/model3" element={<RegisterModel3 />} />
          <Route path="/model-register" element={<LoginModel />} />
          <Route path="/model-password" element={<LoginPasswordModel />} />
          {/*------------------------login/register Artist--------------------------*/}
          <Route path="/artist/sign-up/artist" element={<RegisterArtist />} />
          <Route path="/artist/sign-up/artist2" element={<RegisterArtist2 />} />
          <Route path="/artist/sign-up/artist3" element={<RegisterArtist3 />} />
          <Route path="/artist-register" element={<LoginArtist />} />
          <Route path="/artist-password" element={<LoginPasswordArtist />} />
          <Route path="/register/success" element={<RegisterSuccessful />} />
          <Route path="/login/success" element={<LoginSuccessful />} />

          <Route path="*" element={<Navigate to="/sign-up" replace />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default MainRouter;
