import React from "react";
import MainRouter from "../Routes/MainRouter";

const Layout = () => {
  return (
    <div>
      <MainRouter />
    </div>
  );
};

export default Layout;
