import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";
const { API_BASE_URL } = Config;

// -----------------------------ModelStatusCheck---------------------------
// https//www.admin.viralkar.com/front/api/model-status
export const ModelStatusCheck = async (data, navigate) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/model-status`, data);

    const responseData = response.data;

    if (responseData.responseData && response.data.status === true) {
      const { Id, Email, StageName, Phone, Step1, Step2, Step3 } =
        responseData.responseData;

      sessionStorage.setItem("ModelIdForTemp", JSON.stringify(Id));
      sessionStorage.setItem("ModelEmailForTemp", JSON.stringify(Email));
      sessionStorage.setItem("ModelUserNameForTemp", JSON.stringify(StageName));
      sessionStorage.setItem("ModelPhoneForTemp", JSON.stringify(Phone));
      sessionStorage.setItem("ModelStep1ForTemp", JSON.stringify(Step1));
      sessionStorage.setItem("ModelStep2ForTemp", JSON.stringify(Step2));
      sessionStorage.setItem("ModelStep3ForTemp", JSON.stringify(Step3));

      if (Step1 === 0 && Step2 === 0 && Step3 === 0) {
        navigate("/model/sign-up/model");
        return responseData; // Exit the function
      } else if (Step1 === 1 && Step2 === 0 && Step3 === 0) {
        navigate("/model/sign-up/model2");
        return responseData; // Exit the function
      } else if (Step1 === 1 && Step2 === 1 && Step3 === 0) {
        navigate("/model/sign-up/model3");
        return responseData; // Exit the function
      } else {
        toast.success(
          "Email/Username Verification Successful! Welcome back, ready to strut your stuff?"
        );
        navigate("/model-password");
        return responseData; // Exit the function
      }
    }

    if (responseData.InsertId && response.data.status === true) {
      const InsertId = responseData.InsertId;

      if (InsertId) {
        sessionStorage.setItem("ModelIdForTemp", JSON.stringify(InsertId));
        navigate("/model/sign-up/model");
        toast.success(
          "Registration Successful! Complete your profile to become the next top model."
        );
      }
    }
  } catch (error) {
    navigate("/model/sign-up/model");
    throw error; // Re-throw the error after handling
  }
};
// -----------------------------ModelStatusCheck---------------------------
// https//www.admin.viralkar.com/front/api/model-status
export const GoogleLoginModelAPI = async (googleData, navigate) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/model-google-login`,
      googleData
    );

    const responseData = response.data;

    if (responseData.responseData && response.data.status === true) {
      const { Id, Email, StageName, Phone, Step1, Step2, Step3 } =
        responseData.responseData;

      sessionStorage.setItem("ModelIdForTemp", JSON.stringify(Id));
      sessionStorage.setItem("ModelEmailForTemp", JSON.stringify(Email));
      sessionStorage.setItem("ModelUserNameForTemp", JSON.stringify(StageName));
      sessionStorage.setItem("ModelPhoneForTemp", JSON.stringify(Phone));
      sessionStorage.setItem("ModelStep1ForTemp", JSON.stringify(Step1));
      sessionStorage.setItem("ModelStep2ForTemp", JSON.stringify(Step2));
      sessionStorage.setItem("ModelStep3ForTemp", JSON.stringify(Step3));

      if (Step1 === 0 && Step2 === 0 && Step3 === 0) {
        navigate("/model/sign-up/model");
        return responseData; // Exit the function
      } else if (Step1 === 1 && Step2 === 0 && Step3 === 0) {
        navigate("/model/sign-up/model2");
        return responseData; // Exit the function
      } else if (Step1 === 1 && Step2 === 1 && Step3 === 0) {
        navigate("/model/sign-up/model3");
        return responseData; // Exit the function
      } else {
        toast.success(
          "Email/Username Verification Successful! Welcome back, ready to strut your stuff?"
        );
        navigate("/model-password");
        return responseData; // Exit the function
      }
    }

    if (responseData.InsertId && response.data.status === true) {
      const InsertId = responseData.InsertId;

      if (InsertId) {
        sessionStorage.setItem("ModelIdForTemp", JSON.stringify(InsertId));
        navigate("/model/sign-up/model");
        toast.success(
          "Registration Successful! Complete your profile to become the next top model."
        );
      }
    }
  } catch (error) {
    navigate("/model/sign-up/model");
    throw error; // Re-throw the error after handling
  }
};

// -----------------------------ModelStatusCheck---------------------------
// https//www.admin.viralkar.com/front/api/model-status
export const ModelPassword = async (data, navigate) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/model-verify`, data);

    const responseData = response.data;

    if (response.data.status === true) {
      sessionStorage.setItem(
        "ModelId",
        JSON.stringify(responseData.responseData.ModelId)
      );
      sessionStorage.setItem(
        "ModelEmail",
        JSON.stringify(responseData.responseData.ModelEmail)
      );
      sessionStorage.setItem(
        "ModelPhone",
        JSON.stringify(responseData.responseData.ModelPhone)
      );
      sessionStorage.setItem(
        "ModelUserName",
        JSON.stringify(responseData.responseData.ModelUserName)
      );
      sessionStorage.setItem(
        "ModelType",
        JSON.stringify(responseData.responseData.Type)
      );
      sessionStorage.setItem(
        "ModelStatus",
        JSON.stringify(responseData.responseData.Status)
      );

      //   -----------------------------remove----------------------------
      sessionStorage.removeItem("ModelIdForTemp");
      sessionStorage.removeItem("ModelEmailForTemp");
      sessionStorage.removeItem("ModelUserNameForTemp");
      sessionStorage.removeItem("ModelPhoneForTemp");
      sessionStorage.removeItem("ModelStep1ForTemp");
      sessionStorage.removeItem("ModelStep2ForTemp");
      sessionStorage.removeItem("ModelStep3ForTemp");

      toast.success(
        "Welcome back, model! You’re logged in and ready to hit the runway!"
      );
    } else {
      toast.success(
        "Welcome back, model! You’re logged in and ready to hit the runway!"
      );
    }

    return responseData;
  } catch (error) {
    toast.success(
      "Welcome back, model! You’re logged in and ready to hit the runway!"
    );
    throw error;
  }
};

// -----------------------------ModelStep1Get---------------------------
// https//www.admin.viralkar.com/front/api/model-step-1

export const ModelGetById = async (Id) => {
  try {
    const responce = await axios.get(`${API_BASE_URL}/model/${Id}`);

    if (responce.data.status === true) {
      return responce.data.responseData[0];
    } else {
      throw new Error(responce.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// -----------------------------ModelStep1Add---------------------------
// https//www.admin.viralkar.com/front/api/model-step-1
export const ModelStep1Add = async (data) => {
  try {
    const responce = await axios.post(`${API_BASE_URL}/model-step-1`, data);

    if (responce.data.status === true) {
      toast.success(
        "Step 1 Complete! You’re on your way to becoming the next top model!"
      );
      return responce.data;
    } else {
      toast.error(
        "Oops! Something went wrong. Please check your details and try again to start your modeling journey."
      );
    }
  } catch (error) {
    throw error;
  }
};
// -----------------------------ModelStep2Add---------------------------
// https//www.admin.viralkar.com/front/api/model-step-2
export const ModelStep2Add = async (data) => {
  try {
    const responce = await axios.post(`${API_BASE_URL}/model-step-2`, data);

    if (responce.data.status === true) {
      toast.success(
        "Step 2 Done! Your portfolio is coming together beautifully."
      );
      return responce.data;
    } else {
      toast.error(
        "Hmm, something’s off. Please refresh and try submitting your profile details again."
      );
    }
  } catch (error) {
    throw error;
  }
};
// -----------------------------ModelStep3Add---------------------------
// https//www.admin.viralkar.com/front/api/model-step-2
export const ModelStep3Add = async (data) => {
  try {
    const responce = await axios.post(`${API_BASE_URL}/model-step-3`, data);

    if (responce.data.status === true) {
      toast.success(
        "Congratulations! Your modeling profile is live-time to own the runway!"
      );
      return responce.data;
    } else {
      toast.error(
        "Oh no! We couldn’t finalize your profile. Let’s try again so you can hit the spotlight!"
      );
    }
  } catch (error) {
    throw error;
  }
};
