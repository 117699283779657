/* eslint-disable react/jsx-no-target-blank */
// import React from "react";
// import {
//   FaFacebook,
//   FaGlobe,
//   FaInstagram,
//   FaLocationArrow,
//   FaTwitch,
//   FaYoutube,
// } from "react-icons/fa";
// import logo from "../../Images/logo.png";
// import { FaXTwitter } from "react-icons/fa6";
// const Footer = () => {
//   return (
//     <div className="relative">
//       <div className="flex items-center absolute left-1/2 pt-28  transform -translate-x-1/2 -translate-y-1/2 justify-center flex-col sm:flex-row">
//         <div className="bg-themecolor w-72 text-black text-center h-44 flex flex-col items-center justify-center p-3 m-2">
//           <FaLocationArrow className="mx-auto text-3xl mb-2" />
//           <p className="text-xl font-bold mb-2">Address</p>
//           <p className="font-bold">
//             A-311, Mondeal height, Nr. Novotel hotel, S.G Highway, Nr. Iscon
//             Circle, Ahmedabad
//           </p>
//         </div>
//         <div className="bg-themecolor w-72 text-black text-center h-44 flex flex-col items-center justify-center p-3 m-2">
//           <FaGlobe className="mx-auto text-3xl mb-2" />
//           <p className="text-xl font-bold mb-2">Portfolio</p>
//           <p className="font-bold">xyz@gmail.com</p>
//         </div>
//       </div>
//       <div className="bg-black pt-48">
//         <div className="text-black flex items-center justify-center mb-5">
//           <span className="bg-themecolor mx-3 p-1.5 rounded cursor-pointer">
//             <FaFacebook className="text-3xl" />
//           </span>
//           <span className="bg-themecolor mx-3 p-1.5 rounded cursor-pointer">
//             <FaXTwitter className="text-3xl" />
//           </span>
//           <span className="bg-themecolor mx-3 p-1.5 rounded cursor-pointer">
//             <FaInstagram className="text-3xl" />
//           </span>
//           <span className="bg-themecolor mx-3 p-1.5 rounded cursor-pointer">
//             <FaYoutube className="text-3xl" />
//           </span>
//         </div>
//         <div className="container mx-auto p-5">
//           <img src={logo} alt="" className="w-96 mx-auto" />
//           <div className="grid grid-cols-1 md:grid-cols-3 my-10">
//             <div className="text-black col-span-1 md:mx-auto my-5">
//               <span className="font-bold text-3xl border-b-2 border-themecolor">
//                 READING LINKS
//               </span>
//               <div className="mt-6">
//                 <p className="text-xl font-bold hover:text-themecolor cursor-pointer my-2">
//                   About Us
//                 </p>
//                 <p className="text-xl font-bold hover:text-themecolor cursor-pointer my-2">
//                   Services
//                 </p>
//                 <p className="text-xl font-bold hover:text-themecolor cursor-pointer my-2">
//                   Plan
//                 </p>
//                 <p className="text-xl font-bold hover:text-themecolor cursor-pointer my-2">
//                   FAQ
//                 </p>
//               </div>
//             </div>
//             <div className="text-black col-span-1 md:mx-auto my-5">
//               <span className="font-bold text-3xl border-b-2 border-themecolor">
//                 AUDITIONS
//               </span>
//               <div className="mt-6">
//                 <p className="text-xl font-bold hover:text-themecolor cursor-pointer my-2">
//                   Register as a model
//                 </p>
//                 <p className="text-xl font-bold hover:text-themecolor cursor-pointer my-2">
//                   Register as an Influencer
//                 </p>
//                 <p className="text-xl font-bold hover:text-themecolor cursor-pointer my-2">
//                   Hire as a model
//                 </p>
//                 <p className="text-xl font-bold hover:text-themecolor cursor-pointer my-2">
//                   Hire an Influencer
//                 </p>
//               </div>
//             </div>
//             <div className="text-black col-span-1 md:mx-auto my-5">
//               <span className="font-bold text-3xl border-b-2 border-themecolor">
//                 REACH US
//               </span>
//               <div className="mt-6">
//                 <p className="text-xl font-bold hover:text-themecolor cursor-pointer my-2">
//                   Sign Up as Model
//                 </p>
//                 <p className="text-xl font-bold hover:text-themecolor cursor-pointer my-2">
//                   Sign Up as Influencer
//                 </p>
//                 <p className="text-xl font-bold hover:text-themecolor cursor-pointer my-2">
//                   Sign Up as Enterprise
//                 </p>
//                 <p className="text-xl font-bold hover:text-themecolor cursor-pointer my-2">
//                   Contact Us
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="bg-themecolor">
//         <p className="text-xl font-bold text-black py-1.5 text-center">
//           © 2024 | All right Reserved | Design & Developed By Seawind Solution
//           Pvt. Ltd.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Footer;

import React, { useEffect, useState } from "react";
import {
  FaFacebook,
  FaFacebookF,
  FaGooglePlusG,
  FaInstagram,
  FaLinkedin,
  FaMoon,
  FaSun,
  FaTelegramPlane,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import logo from "../../Images/logo.png";
import loader from "../../Images/loaderimage.webp";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { SlScreenDesktop } from "react-icons/sl";
import { Link } from "react-router-dom";
import { IoMdMail } from "react-icons/io";
import { SiGunicorn } from "react-icons/si";
import SunImg from "../../Images/sun.png";
import MoonImg from "../../Images/night.png";

const Footer = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme === "dark" : false;
  });

  useEffect(() => {
    // Apply the theme to the body element
    document.body.className = isDarkMode ? "dark" : "";
    // Save the theme to localStorage
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <>
      <div className="bottom-10 lg:left-8 left-4 fixed z-10">
        <button
          onClick={() => setIsDarkMode(!isDarkMode)}
          className="flex items-center justify-center p-2 border border-slate-500 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-gray-900 dark:text-white"
        >
          {isDarkMode ? (
            <img src={SunImg} alt="" className="w-6" />
          ) : (
            <img src={MoonImg} alt="" className="w-6" />
          )}
          <span className="sr-only">
            {isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
          </span>
        </button>
      </div>

      <div className="relative">
        {/* <div className="flex items-center absolute left-1/2 pt-28  h-52 transform -translate-x-1/2 -translate-y-1/2 justify-center flex-col sm:flex-row">
        <div className="bg-themecolor w-72 text-black text-center   flex flex-col items-center justify-center p-3 m-2">
          <FaLocationDot className="mx-auto text-6xl mb-2" />
          <p className="text-2xl  mb-2">Address</p>
          <p className="">
            A-311, Mondeal height, Nr. Novotel hotel, S.G Highway, Nr. Iscon
            Circle, Ahmedabad
          </p>
        </div>
        <div className="bg-themecolor w-72 text-black text-center  h-52 flex flex-col items-center justify-center p-3 m-2">
          <SlScreenDesktop className="mx-auto text-6xl mb-2" />
          <p className="text-xl font-bold mb-2">Portfolio</p>
          <p className="font-bold">xyz@gmail.com</p>
        </div>
      </div> */}

        <div className="bg-white dark:bg-slate-950 border-t">
          <div className="container mx-auto p-5">
            <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2">
              <div className="text-black dark:text-white col-span-1 md:mx-auto my-5">
                <img
                  src={logo}
                  alt=""
                  className="w-52 mx-auto bg-white rounded-md mb-5"
                />

                <div className=" text-black dark:text-white text-center flex   items-center mb-4">
                  <h1 className="border border-black dark:border-white rounded-full flex items-center justify-center p-1.5">
                    <IoMdMail className="text-xl" />
                  </h1>

                  <a
                    href="mailto:connect@viralkar.com"
                    className="text-left ps-2 text-sm"
                  >
                    connect@viralkar.com
                  </a>
                </div>
                <div className=" mt-6 md:mt-7 mb-4 lg:mb-0 text-black xl:mx-auto   text-left">
                  <p className="text-black dark:text-white font-semibold mb-2 border-b-2 border-themecolor table underline-offset-8 text-lg">
                    Follow Us
                  </p>
                  <div className="my-auto flex mx-auto mt-5">
                    <Link to={"https://x.com/viral_kar_"} target="_blank">
                      <h2 className="bg-black text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                        <FaXTwitter />
                      </h2>
                    </Link>
                    <Link
                      to={"https://www.facebook.com/viralkarr/"}
                      target="_blank"
                    >
                      <h2 className="bg-[#0165E1] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                        <FaFacebookF />
                      </h2>
                    </Link>

                    <Link
                      to={"https://www.instagram.com/viral_kar_"}
                      target="_blank"
                    >
                      <h2 className="bg-instagram text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                        <FaInstagram />
                      </h2>
                    </Link>
                    <Link
                      to={"https://www.linkedin.com/company/viralkar"}
                      target="_blank"
                    >
                      <h2 className="bg-[#27A7E7] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                        <FaLinkedin />
                      </h2>
                    </Link>
                    <Link
                      to={"https://www.youtube.com/@ViralKarr"}
                      target="_blank"
                    >
                      <h2 className="bg-red-600 text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                        <FaYoutube />
                      </h2>
                    </Link>
                    {/* <Link to={"https://www.whatsapp.com/"} target="_blank">
                      <h2 className="bg-[#25D366] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                        <FaWhatsapp />
                      </h2>
                    </Link> */}
                  </div>
                </div>
              </div>

              <div className="text-black dark:text-white col-span-1 md:mx-auto my-5">
                <span className="font-semibold text-xl border-b-2 border-themecolor">
                  Reading Links
                </span>
                <div className="mt-6">
                  <p className="my-2">
                    <Link className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer">
                      About Us
                    </Link>
                  </p>
                  <p className="my-2">
                    <Link className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer">
                      Services
                    </Link>
                  </p>
                  <p className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer my-2">
                    <Link>Plan</Link>
                  </p>
                  <p className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer my-2">
                    <Link>FAQ</Link>
                  </p>
                  <p className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer my-2">
                    <Link>Privacy Policy</Link>
                  </p>
                  <p className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer my-2">
                    <Link>Terms & Conditions</Link>
                  </p>
                </div>
              </div>
              <div className="text-black dark:text-white col-span-1 md:mx-auto my-5">
                <span className="font-semibold text-xl border-b-2 border-themecolor">
                  Auditions
                </span>
                <div className="mt-6">
                  <p className="my-2">
                    <Link className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer">
                      Register as a model
                    </Link>
                  </p>
                  <p className="my-2">
                    <Link className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer">
                      Register as an Influencer
                    </Link>
                  </p>
                  <p className="my-2">
                    <Link className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer">
                      Hire as a model
                    </Link>
                  </p>
                  <p className="my-2">
                    <Link className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer">
                      Hire an Influencer
                    </Link>
                  </p>
                </div>
              </div>
              <div className="text-black dark:text-white col-span-1 md:mx-auto my-5">
                <span className="font-semibold text-xl border-b-2 border-themecolor">
                  Reach Us
                </span>
                <div className="mt-6">
                  <p className="my-2">
                    <Link className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer">
                      Sign Up as Model
                    </Link>
                  </p>
                  <p className="my-2">
                    <Link className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer">
                      Sign Up as Influencer
                    </Link>
                  </p>
                  <p className="my-2">
                    <Link className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer">
                      Sign Up as Artist
                    </Link>
                  </p>
                  <p className="my-2">
                    <Link className="text-sm text-gray-600 font-medium hover:text-themecolor cursor-pointer">
                      Contact Us
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-themecolor loader-first1">
          <p className="text-balance font-normal text-white py-2 text-center text-xs flex items-center justify-center flex-col md:flex-row">
            © 2024 | All right Reserved | Design & Developed By{" "}
            <a
              href="https://seawindsolution.com/"
              target="_blank"
              className="ml-1"
            >
              Seawind Solution Pvt. Ltd.{" "}
              <img src={loader} className="animate-rotate w-16" alt="" />
            </a>
          </p>
        </div>
      </div>
      <div className="fixed top-[33%] right-12 z-10 flex flex-col">
        <a
          href="https://x.com/viral_kar_"
          target="_blank"
          className="flex items-center bg-black text-white rounded-l-full mb-2 p-2 uppercase transition-transform duration-700 transform translate-x-[160px] "
        >
          <FaXTwitter className="bg-white text-black rounded-full h-8 w-8 mr-5 p-2 transition-transform duration-500 hover:rotate-[360deg]" />
          Twitter
        </a>

        <a
          href="https://www.facebook.com/viralkarr/"
          target="_blank"
          className="flex items-center bg-[#0165E1] text-white rounded-l-full mb-2 p-2 uppercase transition-transform duration-700 transform translate-x-[160px] "
        >
          <FaFacebookF className="bg-white text-[#2C80D3] rounded-full h-8 w-8 mr-5 p-2 transition-transform duration-500 hover:rotate-[360deg]" />
          Facebook
        </a>
        <a
          href="https://www.instagram.com/viral_kar_"
          target="_blank"
          className="flex items-center bg-instagram text-white rounded-l-full mb-2 p-2 uppercase transition-transform duration-700 transform translate-x-[160px] "
        >
          <FaInstagram className="bg-white text-[#FD1D1D] rounded-full h-8 w-8 mr-5 p-2 transition-transform duration-500 hover:rotate-[360deg]" />
          Instagram
        </a>
        <a
          href="https://www.linkedin.com/company/viralkar"
          target="_blank"
          className="flex items-center bg-[#27A7E7] text-white rounded-l-full mb-2 p-2 uppercase transition-transform duration-700 transform translate-x-[160px] "
        >
          <FaLinkedin className="bg-white text-[#27A7E7] rounded-full h-8 w-8 mr-5 p-2 transition-transform duration-500 hover:rotate-[360deg]" />
          Linkedin
        </a>
        <a
          href="https://www.youtube.com/@ViralKarr"
          target="_blank"
          className="flex items-center bg-[#FA0910] text-white rounded-l-full mb-2 p-2 uppercase transition-transform duration-700 transform translate-x-[160px] "
        >
          <FaYoutube className="bg-white text-[#FA0910] rounded-full h-8 w-8 mr-5 p-2 transition-transform duration-500 hover:rotate-[360deg]" />
          YouTube
        </a>
      </div>
    </>
  );
};

export default Footer;
