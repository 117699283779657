import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";
const { API_BASE_URL } = Config;

// -----------------------------InfluencerStatusCheck---------------------------
// https//www.admin.viralkar.com/front/api/influencer-status
export const InfluencerStatusCheck = async (data, navigate) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/influencer-status`,
      data
    );

    const responseData = response.data;

    if (responseData.responseData && response.data.status === true) {
      const { Id, Email, UserName, Phone, Step1, Step2, Step3 } =
        responseData.responseData;
      sessionStorage.setItem("InfluencerIdForTemp", JSON.stringify(Id));
      sessionStorage.setItem("InfluencerEmailForTemp", JSON.stringify(Email));
      sessionStorage.setItem(
        "InfluencerUserNameForTemp",
        JSON.stringify(UserName)
      );
      sessionStorage.setItem("InfluencerPhoneForTemp", JSON.stringify(Phone));
      sessionStorage.setItem("InfluencerStep1ForTemp", JSON.stringify(Step1));
      sessionStorage.setItem("InfluencerStep2ForTemp", JSON.stringify(Step2));
      sessionStorage.setItem("InfluencerStep3ForTemp", JSON.stringify(Step3));

      if (Step1 === 0 && Step2 === 0 && Step3 === 0) {
        navigate("/influencer/sign-up/influencer");
        return responseData; // Exit the function
      } else if (Step1 === 1 && Step2 === 0 && Step3 === 0) {
        navigate("/influencer/sign-up/influencer2");
        return responseData; // Exit the function
      } else if (Step1 === 1 && Step2 === 1 && Step3 === 0) {
        navigate("/influencer/sign-up/influencer3");
        return responseData; // Exit the function
      } else {
        toast.success(
          "Email/Username Verification Successful! Welcome back, let’s continue your journey."
        );
        navigate("/influencer-password");
        return responseData; // Exit the function
      }
    }

    if (responseData.InsertId && response.data.status === true) {
      const InsertId = responseData.InsertId;

      if (InsertId) {
        sessionStorage.setItem("InfluencerIdForTemp", JSON.stringify(InsertId));
        navigate("/influencer/sign-up/influencer");
        toast.success(
          "You’re registered! Finish setting up your profile to unlock your influencer potential."
        );
      }
    }
  } catch (error) {
    navigate("/influencer/sign-up/influencer");
    throw error; // Re-throw the error after handling
  }
};

export const GoogleLoginInfluencerAPI = async (googleData, navigate) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/influencer-google-login`,
      googleData
    );

    const responseData = response.data;

    if (responseData.responseData && response.data.status === true) {
      const { Id, Email, UserName, Phone, Step1, Step2, Step3 } =
        responseData.responseData;
      sessionStorage.setItem("InfluencerIdForTemp", JSON.stringify(Id));
      sessionStorage.setItem("InfluencerEmailForTemp", JSON.stringify(Email));
      sessionStorage.setItem(
        "InfluencerUserNameForTemp",
        JSON.stringify(UserName)
      );
      sessionStorage.setItem("InfluencerPhoneForTemp", JSON.stringify(Phone));
      sessionStorage.setItem("InfluencerStep1ForTemp", JSON.stringify(Step1));
      sessionStorage.setItem("InfluencerStep2ForTemp", JSON.stringify(Step2));
      sessionStorage.setItem("InfluencerStep3ForTemp", JSON.stringify(Step3));

      if (Step1 === 0 && Step2 === 0 && Step3 === 0) {
        navigate("/influencer/sign-up/influencer");
        return responseData; // Exit the function
      } else if (Step1 === 1 && Step2 === 0 && Step3 === 0) {
        navigate("/influencer/sign-up/influencer2");
        return responseData; // Exit the function
      } else if (Step1 === 1 && Step2 === 1 && Step3 === 0) {
        navigate("/influencer/sign-up/influencer3");
        return responseData; // Exit the function
      } else {
        toast.success(
          "Email/Username Verification Successful! Welcome back, let’s continue your journey."
        );
        navigate("/influencer-password");
        return responseData; // Exit the function
      }
    }

    if (responseData.InsertId && response.data.status === true) {
      const InsertId = responseData.InsertId;

      if (InsertId) {
        sessionStorage.setItem("InfluencerIdForTemp", JSON.stringify(InsertId));
        navigate("/influencer/sign-up/influencer");
        toast.success(
          "You’re registered! Finish setting up your profile to unlock your influencer potential."
        );
      }
    }
  } catch (error) {
    navigate("/influencer/sign-up/influencer");
    throw error; // Re-throw the error after handling
  }
};

// -----------------------------InfluencerStatusCheck---------------------------
// https//www.admin.viralkar.com/front/api/influencer-status
export const InfluencerPassword = async (data, navigate) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/influencer-verify`,
      data
    );

    const responseData = response.data;

    if (response.data.status === true) {
      sessionStorage.setItem(
        "InfluencerId",
        JSON.stringify(responseData.responseData.InfluencerId)
      );
      sessionStorage.setItem(
        "InfluencerEmail",
        JSON.stringify(responseData.responseData.InfluencerEmail)
      );
      sessionStorage.setItem(
        "InfluencerPhone",
        JSON.stringify(responseData.responseData.InfluencerPhone)
      );
      sessionStorage.setItem(
        "InfluencerUserName",
        JSON.stringify(responseData.responseData.InfluencerUserName)
      );
      sessionStorage.setItem(
        "InfluencerType",
        JSON.stringify(responseData.responseData.Type)
      );
      sessionStorage.setItem(
        "InfluencerStatus",
        JSON.stringify(responseData.responseData.Status)
      );

      //   -----------------------------remove----------------------------
      sessionStorage.removeItem("InfluencerIdForTemp");
      sessionStorage.removeItem("InfluencerEmailForTemp");
      sessionStorage.removeItem("InfluencerUserNameForTemp");
      sessionStorage.removeItem("InfluencerPhoneForTemp");
      sessionStorage.removeItem("InfluencerStep1ForTemp");
      sessionStorage.removeItem("InfluencerStep2ForTemp");
      sessionStorage.removeItem("InfluencerStep3ForTemp");

      toast.success(
        "Welcome back, influencer! You’re logged in and ready to shine!"
      );
    } else {
      toast.success(
        "Welcome back, influencer! You’re logged in and ready to shine!"
      );
    }

    return responseData;
  } catch (error) {
    toast.success(
      "Welcome back, influencer! You’re logged in and ready to shine!"
    );
    throw error;
  }
};

// -----------------------------InfluencerStep1Get---------------------------
// https//www.admin.viralkar.com/front/api/influencer-step-1

export const InfluencerGetById = async (Id) => {
  try {
    const responce = await axios.get(`${API_BASE_URL}/influencer/${Id}`);

    if (responce.data.status === true) {
      return responce.data.responseData[0];
    } else {
      throw new Error(responce.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// -----------------------------InfluencerStep1Add---------------------------
// https//www.admin.viralkar.com/front/api/influencer-step-1
export const InfluencerStep1Add = async (data) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/influencer-step-1`,
      data
    );

    if (responce.data.status === true) {
      toast.success(
        "Step 1 Complete! You’re off to a great start-your influencer journey has just begun!"
      );
      return responce.data;
    } else {
      toast.error(
        "Oh no! We couldn’t finalize your profile. Let’s retry and get you live to connect with your followers!"
      );
    }
  } catch (error) {
    throw error;
  }
};
// -----------------------------InfluencerStep2Add---------------------------
// https//www.admin.viralkar.com/front/api/influencer-step-2
export const InfluencerStep2Add = async (data) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/influencer-step-2`,
      data
    );

    if (responce.data.status === true) {
      toast.success(
        "Step 2 Done! Your profile is coming together - just one step away from going live!"
      );
      return responce.data;
    } else {
      toast.error(
        "We hit a snag! Please try submitting your profile details again and let’s keep your journey going."
      );
    }
  } catch (error) {
    throw error;
  }
};
// -----------------------------InfluencerStep3Add---------------------------
// https//www.admin.viralkar.com/front/api/influencer-step-2
export const InfluencerStep3Add = async (data) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/influencer-step-3`,
      data
    );

    if (responce.data.status === true) {
      toast.success(
        "Congrats! You’re officially an influencer-ready to make your mark!"
      );
      return responce.data;
    } else {
      toast.error(
        "Oh no! We couldn’t finalize your profile. Let’s retry and get you live to connect with your followers!"
      );
    }
  } catch (error) {
    throw error;
  }
};
